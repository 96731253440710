import React, { Fragment } from "react"
import Down from "../../assets/images/icons/down.svg"
import Up from "../../assets/images/icons/up.svg"
import classNames from "classnames"
import { content } from "./productFeatures.static"

const Expandable = ({ title, value }) => {
  const [expanded, setExpanded] = React.useState(false)

  const toggleExpanded = () => {
    setExpanded(prevState => !prevState)
  }

  return (
    <div className={classNames("expansion", { ["expanded"]: expanded })}>
      <div className="flex pointer" onClick={toggleExpanded}>
        <h3
          data-sal="slide-left"
          data-sal-delay="200"
          data-sal-duration="700"
          className="product-features-text product-features-text--secondary text-blue"
        >
          {title}
        </h3>
        <div className="grow"></div>
        <img src={expanded ? Up : Down} alt="Arrow Down" />
      </div>
      <div className="content">
        <h3
          className="product-features-text product-features-text--content text-black"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </div>
    </div>
  )
}

export default function ProductFeatures() {
  return (
    <React.Fragment>
      <div id="product-features" className="product-features">
        <div className="product-features__container">
          <div className="product-features__left">
            <h2
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              className="product-features-text product-features-text--primary text-teal"
            >
              Features
            </h2>
          </div>
          <div className="product-features__right">
            {content.map((singleContent, index) => {
              const title = Object.keys(singleContent)[0]
              const value = singleContent[title]
              return (
                <Fragment>
                  <Expandable title={title} value={value} />
                  {index < content.length - 1 && (
                    <div className="divider divider-black "></div>
                  )}
                </Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
