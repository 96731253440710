import React from "react"
import { Link } from "gatsby"
import Icon1 from "../../assets/images/icons/diff1.svg"
import Icon2 from "../../assets/images/icons/diff2.svg"
import Icon3 from "../../assets/images/icons/diff3.svg"
import Icon4 from "../../assets/images/icons/diff4.svg"
import Icon5 from "../../assets/images/icons/diff5.svg"
import Icon6 from "../../assets/images/icons/diff6.svg"

const differencesData = [
  {
    icon: Icon1,
    content: `Our dedicated in-house legal
  and technical team has years
  of experience `,
  },
  {
    icon: Icon2,
    content: `Bespoke platform to suit your needs with no requirement to install any program or software
  `,
  },
  {
    icon: Icon3,
    content: `We are secure and comply with the various data privacy regimes across the world
  `,
  },
  {
    icon: Icon4,
    content: `Two layers of security
  authentication
  `,
  },
  {
    icon: Icon5,
    content: `Our remote access solutions
  are proven and tested
  
  `,
  },
  {
    icon: Icon6,
    content: `User friendly and impartial

  `,
  },
]

export default () => {
  return (
    <div id="product-differences" className="product-differences">
      <div className="product-differences__container">
        <h2
          data-sal="slide-right"
          data-sal-delay="200"
          data-sal-duration="700"
          className="product-features-text product-features-text--primary text-teal"
        >
          What sets us apart
        </h2>
        <div className="u-margin-top-large"></div>
        <section className="u-margin-top-large"></section>

        <div className="product-differences__grid">
          {differencesData.map(data => {
            return (
              <div key={data.icon} className="product-differences_grid-item">
                <div className="product-differences__diff-card">
                  <img src={data.icon} alt="" />
                  <div className="u-margin-top-small"></div>
                  <p>{data.content}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
