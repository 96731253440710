import React from "react"
import { demoForm } from "../../constants"

export default () => {
  return (
    <div id="product-demo" className="product-demo">
      <div className="product-demo__container">
        <h4
          className="product-landing__content text-black "
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          We bring to you a full service offering for all of your hearing needs.
          We are experts in virtual hearing solutions for arbitrations,
          mediations, conciliations, adjudications, grievance redressal,
          investigations, ombudsman hearings as well as Lok Adalats and Courts.
        </h4>
        <div className="u-margin-top-small"></div>

        <h4
          className="product-landing__content text-black "
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          We offer best-in-class services, either as standalone components or as
          package solutions, to meet users' needs, wherever they may be.
        </h4>
        <div className="u-margin-top-small"></div>

        <h4
          className="product-landing__content text-black"
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          We utilise the very latest technology to ensure accuracy and speed
          without compromising on quality or security for our users (users
          include litigating parties, lawyers, judges, arbitrators, mediators or
          conciliators, staff, IT staff, legal researchers, registry, etc).
        </h4>
      </div>
      <div className="u-margin-top-medium"></div>

      <div className="product-demo__button-container">
        <a target="__blank" href={demoForm} className="product-demo__button-container__padding">
          <button
            id="teal-button"
            className="btn btn--contained  btn--large btn--rounded"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="700"
          >
            Request a demo
          </button>
        </a>
        <a href="https://app.webnyay.in" target="__blank" className="product-demo__button-container__padding">
          <button
            className="btn btn--contained  btn--large btn--rounded"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="700"
          >
            sign up
          </button>
        </a>
      </div>
    </div>
  )
}
