export const content = [
  {
    "Virtual Hearing Room":
      "Our virtual hearing rooms are access controlled and all information (including documents exchanged during proceedings) is securely stored, searchable and accessible to all parties and their lawyers. The hearing rooms allow parties to interact by exchanging messages in text, audio and video formats. Parties can also exchange documents (in all formats) in the virtual hearing room. Each virtual hearing room indicates who has access to the rooms, including their specific role in each case. There is an in-built mechanism that records all actions of the parties in the room, creating a clear audit trail for the benefit of the judges, staff and registry. The virtual hearing rooms function with a secure, private, access-controlled and in-house video conferencing facility that is hosted on a dedicated secure server. The video conferencing facility has a gallery view (up to 25 participants) and can accommodate up to 200 people at one time. It also has features like screen-sharing, remote control, moderator control, etc. All of the proceedings in the room can be easily recorded and replayed later.",
  },
  {
    "Breakout Rooms": `We provide breakout rooms for the parties. The breakout rooms can be customised as per your requirement. Such breakout rooms can be made for example for 
      <br><br>
      (a) The litigant and its lawyers in a manner than communications are privileged;
      <br><br>
      (b) ADR professionals and their staff;
      <br><br>(c) All ADR professionals (with or without their staff); 
      <br><br>(d) ADR professionals and their legal researchers; 
      <br><br>(e) Multiple plaintiffs or defendants; 
      <br><br>(f) A "negotiation" room where the parties can directly communicate with each other to settle the dispute; 
      <br><br>(g) "mediation" rooms where the parties can communicate with the mediator (together as well as individually); and 
      <br><br>(h) Between a senior advocate or barrister and the instructing advocate or solicitors. Each breakout room has all the functionality as the virtual hearing room, including a private video conferencing facility specific to each breakout room. The breakout rooms are designed for the parties to seamlessly move between the virtual hearing room and breakout rooms.`,
  },
  {
    "Audio synchronisation":
      "The virtual hearing room and each breakout room can be synchronised by telephone for users who do not have access to the internet or have a poor internet connection. Webnyay provides toll-free numbers that people can dial-in to join the virtual hearing room and participate in the proceedings.",
  },
  {
    Streaming:
      'The hearing from the Webnyay rooms can be live-streamed with low latency. Such a streaming service is only available for viewing and can be customised as per your requirements. This feature is particularly for open courts and Lok Adalats as Webnyay believes in the constitutional principle of "open courts". The public should have access to courts and justice should be delivered in open courts.',
  },
  {
    "Access to hearing rooms & Whatsapp Integration":
      "Webnyay provides separate access and log-in details to each user (ie, litigating parties, lawyers, ADR professionals , staff, registry, IT teams, witnesses, experts, etc). Accordingly, each user's log-in details will be unique and based on their role and job functions. The login credentials work simultaneously on multiple devices from different locations. Webnyay also provides a single link login for litigants (through social media apps like WhatsApp, Messenger by Facebook, Google Meet, Telegram or by SMS messages) to access the virtual hearing room and breakout rooms. Such access will only be enabled after the users verify their mobile number through a one-time password (OTP) mechanism.",
  },
  {
    "Digital Signatures":
      "Users can use the digital signatures capability to affix their digital signatures on pleadings, applications, affidavits, orders, judgments or other documents using Webnyay’s integrated functionality. This also reduces carbon footprint and increases speed and efficiency.",
  },
  {
    Dashboard: `Upon login, each user has a dashboard in order to see the cases that it is engaged in. The dashboard has basic details for each dispute like
  (a) names of the parties in the dispute
  (b) dispute number
  (c) date of filing the dispute
  (d) present status of the dispute. There is also a separate dashboard for cases that have been disposed off
  `,
  },
  {
    "In-house drive to store documents in the cloud": `Each user has access to a dedicated drive (ie, storage area) for every dispute that the user is working on. All files in this drive are stored in the cloud and can be shared with others using the virtual hearing room or breakout rooms in a secure manner.`,
  },
  {
    Calendar: `Each user has an in-built calendar that records dates of all cases that the user is either litigating or working on. The calendars can even be shared with others and are very similar to Google Calendar or Outlook Calendar.`,
  },
  {
    Transcription: `Webnyay offers automatic AI enabled transcription services in the English language for each hearing. The transcript is made available to the users after the hearing concludes.

  If required, the parties can request for manual transcription and choose between real-time (i.e. live transcript), end-of-day and next day solutions. The transcripts can also be synchronised with the audio recording of the hearing.
  
  `,
  },
  {
    "Electronic presentation of evidence (EPE)": `An experienced EPE operator will take care of displaying and navigating the evidence whenever required during the hearing. This may be in support of oral submissions by showing the evidence called by arguing lawyer; it can also help make cross-examinations more effective by seamlessly presenting the evidence under discussion, and enlarging and/or highlighting the passages referred to by the arguing lawyer. Documents can be displayed side-by-side, annotated and the annotations saved in order to be added to the record.
    `,
  },
  {
    "Technology and Integration": `Webnyay is built upon the application programming interface (API) technology and can easily and seamlessly integrate with any other IT systems, software, applications or platforms. 

  Webnyay’s hearing and breakout rooms integrate seamlessly with most of the leading audio and video conferencing solutions available in the market, such as Cisco Webex, Zoom, and Microsoft Teams.
  `,
  },
  {
    "Technical Support": `Throughout the hearing, Webnyay's operators will be available to avoid the hearing participants getting distracted by the technology and to troubleshoot any issues.

Our operators are used to accommodating different time zones and adapting to tech offered in numerous jurisdictions. 
`,
  },
]
