import React from "react"

import Layout from "../components/layout"
import Landing from "../components/productSections/productLanding"
import Demo from "../components/productSections/productdemo"
import Features from "../components/productSections/productFeatures"
import Differences from "../components/productSections/productDifferences"

const ProductPage = () => (
  <Layout>
    <Landing />
    <Demo />
    <Features />
    <Differences />
  </Layout>
)

export default ProductPage
