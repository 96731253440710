import React from "react"
import Rafiki from "../../assets/images/vectors/rafiki5.svg"

export default function AboutLanding() {
  return (
    <React.Fragment>
      <div id="product-landing" className="product-landing">
        <div className="buffer only-short-height"></div>

        <div className="product-landing__container">
          <div className="product-landing__left">
            <h1 className="product-landing__heading">Webnyay RV</h1>
            <div className="u-margin-top-small"></div>
            <h3 className="product-landing__caption">RV / Resolve Virtually</h3>
            <div className="u-margin-top-small"></div>

            <h4
              className="product-landing__content text-white"
              data-sal="slide-down"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              Webnyay RV is a cloud-based comprehensive audio and video
              conferencing solution, including secure hearing and breakout
              rooms, virtual venue locking, private chat functionality and
              screen-sharing. As part of the service we will accompany you on
              setup, testing and managing the virtual hearing venue during the
              hearing.
            </h4>
          </div>
          <div className="product-landing__grow"></div>
          <div className="product-landing__right">
            <img
              src={Rafiki}
              alt="product-landing page main image"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="700"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
